<template>
  <nav class="bg-strik bg-right bg-repeat-y pt-12">
    <div class="w-4/5 mx-auto">
      <ImageContainer class="mb-12">
        <img src="@/assets/head.png" />
      </ImageContainer>
      <SidebarNav class="mb-4" />
      <div class="flex flex-col items-end">
        <a
          class="block cursor-pointer w-1/3 lg:w-1/4 hover:opacity-60"
          title="Mottandi Instagram"
          href="https://www.instagram.com/mottandi"
        >
          <ImageContainer class="p-2">
            <img
              width="64"
              height="64"
              src="@/assets/icon-Instagram_square.png"
            />
          </ImageContainer>
        </a>
        <a
          href="mailto:stefan@mottan.is"
          class="block cursor-pointer w-1/3 lg:w-1/4 hover:opacity-60"
          title="stefan@mottan.is"
        >
          <ImageContainer class="p-2">
            <img width="64" height="64" src="@/assets/Icon-email_square.png" />
          </ImageContainer>
        </a>
      </div>
    </div>
  </nav>
</template>
<script lang="ts">
import ImageContainer from "@/components/img/ImageContainer.vue";
import SidebarNav from "./SidebarNav.vue";
import { defineComponent } from "vue";

export default defineComponent({
  components: {
    SidebarNav,
    ImageContainer,
  },
});
</script>
<style scoped>
nav {
  background-size: 27px 922px;
}
</style>
