<template>
  <div class="md:grid md:grid-cols-4 md:gap-4 lg:grid-cols-5">
    <Sidebar v-if="showSidebar" class="hidden md:block p-4 min-h-screen" />
    <RouterView
      :class="{
        'md:col-span-3 lg:col-span-4': showSidebar,
        'md:col-span-full xl:w-1/4': !showSidebar,
      }"
    />
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import Sidebar from "@/components/nav/Sidebar.vue";

export default defineComponent({
  components: {
    Sidebar,
  },
  name: "App",
  computed: {
    showSidebar(): boolean {
      return !!new URLSearchParams(window.location.search).get("sidebar");
    },
  },
});
</script>
