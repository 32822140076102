<template>
  <div class="w-3/4 sm:w-1/2 lg:w-1/3 mx-auto pt-12">
    <ImageContainer>
      <img src="@/assets/Launcher.png" />
    </ImageContainer>
    <div class="flex justify-end mt-4">
      <a
        class="block cursor-pointer w-1/6 hover:opacity-60"
        title="Mottandi Instagram"
        href="https://www.instagram.com/mottandi"
      >
        <ImageContainer class="pt-2 pb-2">
          <img src="@/assets/icon-Instagram_square.png" />
        </ImageContainer>
      </a>
      <a
        href="mailto:stefan@mottan.is"
        class="block cursor-pointer w-1/6 hover:opacity-60"
        title="stefan@mottan.is"
      >
        <ImageContainer class="pt-2 pb-2">
          <img src="@/assets/Icon-email_square.png" />
        </ImageContainer>
      </a>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import ImageContainer from "@/components/img/ImageContainer.vue";

export default defineComponent({
  components: {
    ImageContainer,
  },
  name: "Home",
});
</script>
