
import { defineComponent } from "vue";
import ImageContainer from "@/components/img/ImageContainer.vue";

export default defineComponent({
  components: {
    ImageContainer,
  },
  name: "Home",
});
