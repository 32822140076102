
import { defineComponent } from "vue";
import SidebarLink from "./SidebarLink.vue";
import ImageContainer from "@/components/img/ImageContainer.vue";

export default defineComponent({
  components: {
    SidebarLink,
    ImageContainer,
  },
  data() {
    return {
      active: "outdoor",
    };
  },
  methods: {
    setActive(link: string): void {
      this.active = link;
    },
  },
});
