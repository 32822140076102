
import { defineComponent } from "vue";
import Sidebar from "@/components/nav/Sidebar.vue";

export default defineComponent({
  components: {
    Sidebar,
  },
  name: "App",
  computed: {
    showSidebar(): boolean {
      return !!new URLSearchParams(window.location.search).get("sidebar");
    },
  },
});
