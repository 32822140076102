<template>
  <ul>
    <SidebarLink @click="setActive('outdoor')" :active="active === 'outdoor'">
      <ImageContainer>
        <img src="@/assets/outdoor.png" />
      </ImageContainer>
    </SidebarLink>
    <SidebarLink @click="setActive('indoor')" :active="active === 'indoor'">
      <ImageContainer>
        <img src="@/assets/indoor.png" />
      </ImageContainer>
    </SidebarLink>
    <SidebarLink @click="setActive('portrett')" :active="active === 'portrett'">
      <ImageContainer>
        <img src="@/assets/portrett.png" />
      </ImageContainer>
    </SidebarLink>
    <SidebarLink @click="setActive('Projekt')" :active="active === 'Projekt'">
      <ImageContainer>
        <img src="@/assets/Projekt.png" />
      </ImageContainer>
    </SidebarLink>
    <SidebarLink @click="setActive('shop')" :active="active === 'shop'">
      <ImageContainer>
        <img src="@/assets/shop.png" />
      </ImageContainer>
    </SidebarLink>
  </ul>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import SidebarLink from "./SidebarLink.vue";
import ImageContainer from "@/components/img/ImageContainer.vue";

export default defineComponent({
  components: {
    SidebarLink,
    ImageContainer,
  },
  data() {
    return {
      active: "outdoor",
    };
  },
  methods: {
    setActive(link: string): void {
      this.active = link;
    },
  },
});
</script>
