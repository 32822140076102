
import { defineComponent } from "vue";
import ImageContainer from "@/components/img/ImageContainer.vue";

export default defineComponent({
  components: {
    ImageContainer,
  },
  props: {
    active: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  name: "SidebarLink",
  methods: {
    onLinkClick(): void {
      this.$emit("click");
    },
  },
});
