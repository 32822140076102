<template>
  <a
    class="cursor-pointer block flex items-center"
    :class="{ active, 'hover:opacity-50': !active }"
    @click="onLinkClick"
  >
    <div class="inline-block w-1/4">
      <ImageContainer class="p-2">
        <img src="@/assets/Icon-blom_square.png" />
      </ImageContainer>
    </div>
    <div class="inline-block w-3/4">
      <slot />
    </div>
  </a>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import ImageContainer from "@/components/img/ImageContainer.vue";

export default defineComponent({
  components: {
    ImageContainer,
  },
  props: {
    active: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  name: "SidebarLink",
  methods: {
    onLinkClick(): void {
      this.$emit("click");
    },
  },
});
</script>
<style scoped>
a > div:first-child > figure {
  opacity: 0;
}

a.active > div:first-child > figure {
  opacity: 1;
}

a:not(.active):hover > div:first-child > figure {
  opacity: 0.5;
}
</style>
