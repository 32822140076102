
import ImageContainer from "@/components/img/ImageContainer.vue";
import SidebarNav from "./SidebarNav.vue";
import { defineComponent } from "vue";

export default defineComponent({
  components: {
    SidebarNav,
    ImageContainer,
  },
});
